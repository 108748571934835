<!-- 详情弹窗 -->
<template>
  <a-modal
    title="详情"
    :width="680"
    :footer="null"
    :visible="visible"
    @update:visible="updateVisible"
  >
    <a-form
      class="ud-form-detail"
      :label-col="{ sm: { span: 11 }, xs: { span: 6 } }"
      :wrapper-col="{ sm: { span: 16 }, xs: { span: 18 } }"
    >
      <a-row :gutter="16">
        <a-col :sm="12" :xs="24">
          <!-- 机柜尺寸 -->
          <a-form-item label="公告ID:">
            <div class="ud-text-secondary">{{ data.noticeId }}</div>
          </a-form-item>
          <!-- 所属运营商 -->
          <a-form-item label="公告标题:">
            <div class="ud-text-secondary">
              {{ data.noticeTitle }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="12" :xs="24">
          <!-- 厂家 -->
          <a-form-item label="公告状态:">
            <div class="ud-text-secondary">
              {{ data.noticeState }}
            </div>
          </a-form-item>
          <!-- 型号 -->
          <a-form-item label="所属运营商:">
            <div class="ud-text-secondary">{{ data.operator }}</div>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'brandDetail',
  emits: ['update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 数据
    data: Object
  },
  methods: {
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style scoped>
</style>
